<template>
  <div>
    <b-table-simple bordered show-empty sort-icon-left small hover responsive>
      <b-thead>
        <b-tr class="table-info">
          <b-th rowspan="2" class="align-top">{{ $t('code') }}</b-th>
          <b-th rowspan="2" class="align-top">{{ $t('group') }}</b-th>
          <b-th rowspan="2" class="align-top">{{ $t('name') }}</b-th>
          <b-th rowspan="2" class="align-top">{{ $t('characteristic') }}</b-th>
          <b-th rowspan="2" class="align-top">{{ $t('production') }}</b-th>
          <b-th rowspan="2" class="align-top text-center">{{ $t('price_usd') }}</b-th>
          <b-th rowspan="2" class="align-top text-center">{{ $t('price') }}</b-th>
          <b-th rowspan="2" class="align-top text-center">{{ $t('price_all') }}</b-th>
          <b-th rowspan="2" class="align-top">{{ $t('reserve') }}</b-th>
          <b-th rowspan="2" class="align-top">{{ $t('free') }}</b-th>
        </b-tr>
      </b-thead>
    </b-table-simple>
  </div>
</template>
<script>
export default {
}
</script>
